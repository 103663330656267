<template>
  <div class="login">
    <h1>AI智能机器人管理系统</h1>
    <div class="login-box">
      <div class="title">注册账号</div>
      <div class="form-box">
        <a-form ref="formRef" :model="formState">
          <a-form-item ref="name" name="phone">
            <a-input
              size="large"
              v-model:value="formState.phone"
              placeholder="请输入手机号"
            >
            </a-input>
          </a-form-item>
          <div class="sendcode">
            <a-form-item ref="password" name="code">
              <a-input
                size="large"
                v-model:value="formState.code"
                placeholder="请输入验证码"
              >
              </a-input>
            </a-form-item>
            <a-button size="large" style="margin-left: 5px"
              >发送验证码</a-button
            >
          </div>
          <a-form-item>
            <a-input
              size="large"
              v-model:value="formState.password"
              placeholder="请输入密码"
            >
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-input
              size="large"
              v-model:value="formState.password2"
              placeholder="请确认密码"
            >
            </a-input>
          </a-form-item>
          <a-button
            type="primary"
            style="margin-top: 10px; width: 100%"
            size="large"
            @click.prevent="onSubmit"
            >登入</a-button
          >
        </a-form>
        <div>
          <a-checkbox
            v-model:checked="checked"
            style="
              margin: 15px 0 20px 0;
              font-size: 12px;
              color: rgb(136, 156, 183);
            "
            >我已阅读并同意《软件注册协议》</a-checkbox
          >
        </div>
        <div class="zhuce">
          已有账号？<label @click="goLogin">去登入</label>
        </div>
      </div>
    </div>
    <div class="foolter">
      <p>用创新和善意推动世界前进</p>
    </div>
  </div>
</template>
<script>
import { toRefs, reactive } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "Register",
  setup() {
    const router = useRouter();
    const state = reactive({
      checked: false,
    });
    const rules = {
      name: [
        {
          required: true,
          message: "请输入用户名",
          trigger: "blur",
        },
        {
          min: 2,
          max: 5,
          message: "长度2到5个字符",
          trigger: "blur",
        },
      ],
      password: [
        {
          required: true,
          message: "请输入密码",
          trigger: "blur",
        },
        {
          min: 6,
          max: 12,
          message: "长度6到12位",
          trigger: "blur",
        },
      ],
    };
    const formState = reactive({
      phone: "",
      code: "",
      password: "",
      password2: "",
    });
    const goLogin = () => {
      router.push({ path: "/login" });
    };
    const onSubmit=()=>{
      console.log("登入")
    }
    return {
      ...toRefs(state),
      rules,
      formState,
      goLogin,onSubmit
    };
  },
};
</script>
<style  scoped>
.login {
  width: 100%;
  height: 100%;
  background: url(../assets/login-bg.svg) no-repeat;
  background-size: 100% 100%;
  background-color: #f9f9fb;
}
.login h1 {
  margin-left: 20px;
}
.login .login-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 500px;
  background-color: #ffffff;
  border-radius: 13px;
  box-shadow: rgb(90 100 170 / 12%) 0px 2px 24px 0px;
  display: flex;
  flex-direction: column;
}
.foolter {
  position: absolute;
  bottom: 25px;
  left: 0;
  width: 100%;
  text-align: center;
  color: rgba(0, 0, 0, 0.25);
}
.title {
  font-size: 20px;
  text-align: center;
  margin: 30px 0;
}
.form-box {
  margin: 0 35px;
  text-align: center;
}
.sendcode {
  display: flex;
  justify-content: space-between;
}
.zhuce {
  font-size: 12px;
  color: rgb(136, 156, 183);
  margin-top: 5px;
}
.zhuce label {
  color: rgb(47, 130, 248);
  cursor: pointer;
}
</style>